import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent } from 'react';
import { type ContentComponentHelper } from '../../../components/construct-components/render-helper/render.helper';
import { type LandingPageContent } from '../../../types/construct.types';
import { DealsTemplate } from './deals/deals.component';
import { FeaturesBenefitsTemplate } from './features-benefits/features-benefits.component';
import { GeneralLandingTemplate } from './general-landing/general-landing.component';
import { SavingsCenterTemplate } from './savings-center/savings-center.component';
import { type TemplateComponent } from './template.types';

type TemplateMap = {
	[k: string]: TemplateComponent;
};

const templateMap: TemplateMap = {
	'deals-template@2': DealsTemplate,
	'features-benefits-template@1': FeaturesBenefitsTemplate,
	'savings-center@3': SavingsCenterTemplate,
	'general-landing@1': GeneralLandingTemplate,
	'sitewide-sales-template@1': DealsTemplate
};

type TemplateRouterProps = {
	content: ContentComponentHelper<LandingPageContent>;
};

export const TemplateRouter: FunctionComponent<TemplateRouterProps> = ({ content }) => {
	const schema = content.schemaType;
	const Template = templateMap[schema];
	if (!Template) {
		logError(`TemplateRouter - unsupported landing page template: ${schema}`);
		return null;
	}
	return <Template content={content} />;
};
