import { kebabCase } from '@fergdigitalcommerce/fergy-utilities';
import { type JumpTabNavButton } from '../../types/jump-tabs.types';

export const DEFAULT_TAB = { label: '', anchor: '', id: '' };

export const findButtonFromAnchor = (anchor: string, navButtons: JumpTabNavButton[]): JumpTabNavButton => {
	return navButtons.find((button) => button.anchor === anchor) || DEFAULT_TAB;
};

// Gets the correct settings for the sliding underline bar
export const getUnderlineSettings = (button: JumpTabNavButton, extraPadding: number): { width: string; marginLeft: string } => {
	const buttonNode = document.getElementById(button.id);
	if (buttonNode && buttonNode.id) {
		return { width: String(buttonNode.clientWidth) + 'px', marginLeft: String(buttonNode.offsetLeft - extraPadding) + 'px' };
	}
	return { width: '0px', marginLeft: '' };
};

type TabProps = {
	tabName: string;
	tabId?: string;
};

export const buildJumpTabButtonProps = (tabProps: TabProps): JumpTabNavButton => {
	const { tabName } = tabProps;
	return {
		label: tabName,
		anchor: getJumpTabAnchor(tabProps),
		id: `${kebabCase(tabName)}-button`
	};
};

export const getJumpTabAnchor = (tabProps: TabProps) => tabProps.tabId || kebabCase(tabProps.tabName);
