import React, { type FunctionComponent, type PropsWithChildren } from 'react';
import { useParams } from 'react-router';
import { PageContainer } from '../../../components/common-components/page/page-container/page-container.component';
import { getSeoMetaData } from '../../../helpers/construct/page-content.helper';
import { type SeoMetaDataContent } from '../../../types/construct.types';

type LandingPageContainerProps = {
	seo: SeoMetaDataContent;
	topContent?: JSX.Element; // content to render above the page container
};

export const LandingPageContainer: FunctionComponent<PropsWithChildren<LandingPageContainerProps>> = ({ seo, children, topContent }) => {
	const { title, description, isIndexed } = getSeoMetaData(seo);
	const { pageName } = useParams<{ pageName?: string }>();
	return (
		<>
			{topContent}
			<PageContainer canonicalURL={`/${pageName}`} pageTitle={title} metaDescription={description} shouldBlockIndexing={!isIndexed}>
				<>{children}</>
			</PageContainer>
		</>
	);
};
