import React from 'react';
import { Placeholder } from '../../../../components/common-components/placeholder/placeholder.component';
import { LandingPageContainer } from '../../components/landing-page-container.component';
import { type TemplateComponent } from '../template.types';

export const FeaturesBenefitsTemplate: TemplateComponent = ({ content }) => {
	const {
		content: {
			fields: { seo, content_sections, hero_content }
		}
	} = content;

	const heroContent = content.render(hero_content);
	return (
		<>
			<LandingPageContainer seo={seo} topContent={heroContent || <Placeholder />}>
				{content.render(content_sections)}
			</LandingPageContainer>
		</>
	);
};
